@import 'scss/variables';

.pwa-timer-container {
  .pwa-timer {
    height: 4px;
    width: 100%;
    background-color: #FFF;
    border-radius: 8px;
    margin-top: 8px;
  }

  .pwa-counter {
    @extend .pwa-timer;
    background-color: $bright-green;
  }

  p.pwa-counter-label {
    margin-top: 4px;
    font-size: 14px;
  }

  &.lapsing {
    .pwa-counter {
      @extend .pwa-timer;
      background-color: $semantic-red;
    }

    p.pwa-counter-label {
      color: $semantic-red;
    }
  }

  &.lapsed {
    .pwa-counter {
      @extend .pwa-timer;
      background-color: #C0C0C0;
      width: 100% !important;
    }

    p.pwa-counter-label {
      font-size: 16px;
    }
  }
}
