@import 'scss/mixins';
@import 'scss/variables';

header {
  z-index: 10;

  @include respond-to-min('large') {
    position: sticky;
    top: 0;
  }

  nav {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    height: $header-height;

    span {
      @include links;
      transition: 0.125s all ease-out;
      padding: 24px 0;

      &:hover {
        text-decoration: none;
        border-bottom: solid 4px $avi-green;
        border-radius: 8px 8px 0 0;
      }
    }

    ul {
      list-style-type: none;
      display: flex;

      li {
        &.home {
          padding-right: 64px;
        }
      }
    }

    .lang {
      @include menu;
      line-height: 24px;
      color: $avi-green;
      padding: 10px; // "Hitslop" for mobile layout
      margin-right: -10px;
      cursor: pointer;
    }

    @include respond-to-max('small') {
      height: $header-height-sm;
      padding: 0 16px;
    }
  }

}
