@import 'scss/mixins';
@import 'scss/variables';

.download-banner {
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  background-color: #fff;
  height: $banner-height;

  .logo-and-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include label;

    .banner-logo {
      width: 47px;
      height: 47px;
      margin-right: 16px;
      border-radius: 10px;
      padding: 11px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 3px 7px rgba(0,0,0,0.13);
    }
  }

  .download-buttons {
    display: flex;
    justify-content: flex-end;

    .banner-button {
      display: flex;
      @include label;
      font-weight: 800;
      color: $avi-green;
      text-transform: uppercase;
      background-color: transparent;
      border: 0;
      border-radius: 6px;
      padding: 9px;

      &:active {
        background-color:#f5f5f5;
      }
    }
  }

  @include respond-to-max('small') {
    display: flex;
  }
}
