@import 'scss/variables';
@import 'scss/mixins';

.radio-input-container {
  font-family: 'ES Build Regular', sans-serif;
  font-feature-settings: normal;
  cursor: pointer;
  user-select: none;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: left;
  border: 1px solid $grey-500;
  border-radius: 4px;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  .radio-icon-container {
    width: 14px;
    height: 14px;
    margin-left: 16px;
    margin-right: 16px;
    opacity: 1;
    border: 1px solid $grey-600;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .radio-icon {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-color: $grey-600;
      transform: scale(0);
      transition: transform 0.5s $cubicBezier;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: $light-peach;
      border: 1px solid $peach;

      .radio-icon-container {

        .radio-icon {
          transform: scale(0.5);
          background-color: $grey-600;
        }
      }
    }
  }

  &:active,
  &.selected {
    .radio-icon-container {
      border-color: $avi-green;

      .radio-icon {
        transform: scale(0.85);
        background-color: $avi-green;
      }
    }
  }

  &:active {
    background: $light-peach;
    border: 1px solid $peach;
  }

  input {
    margin: 0 16px;
    display: none;
  }

  .label-container {
    pointer-events: none;
    padding-right: 16px;
  }
}
