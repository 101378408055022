@import 'variables';

/*****************************************/
/**************** TITLES *****************/
/*****************************************/

@mixin headline1 {
  font-family: 'ES Build Medium', sans-serif;
  font-size: 48px;
  line-height: 74px;
  color: $grey-900;
  font-feature-settings: 'ss01' on;
}

@mixin headline2 {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 32px;
  line-height: 42px;
  color: $grey-900;
  font-feature-settings: 'ss01' on;
}

@mixin headline3 {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 25px;
  line-height: 33px;
  color: $grey-900;
}

@mixin headline4 {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: $grey-900;
}

.title-1 {
  @include headline1;
}

.title-2 {
  @include headline2;
}

.title-3 {
  @include headline3;
}

.title-4 {
  @include headline4;
}

/*****************************************/
/*************** BODY TEXT ***************/
/*****************************************/

@mixin body-big {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  color: $grey-800;
}

@mixin body {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  color: $grey-800;
}

@mixin body-small {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: $grey-800;
}

@mixin body-big-mobile {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  color: $grey-800;
}

@mixin body-mobile {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  color: $grey-800;
}

@mixin body-small-mobile {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: $grey-800;
}

.body-regular {
  @include body;
}

.body-big {
  @include body-big;
}

.body-small {
  @include body-small;
}

.body-mobile {
  @include body-mobile;
}

.body-big-mobile {
  @include body-big-mobile;
}

.body-small-mobile {
  @include body-small-mobile;
}

/*****************************************/

@mixin button-label {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
}

@mixin underlined-links {
  color: $avi-green;
  text-decoration: underline;
  text-underline-offset: 4px;
  transition: color 150ms ease-in-out;

  &:hover,
  &:active,
  &:focus-visible {
    color: $dark-green;
  }
}

@mixin links {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.4em;
  color: $avi-green;
  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    text-decoration: underline;
    color: $dark-green;
  }
}

@mixin menu {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0;
}

@mixin label {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: $grey-700;
}

@mixin button-label-mobile {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0;
  color: $grey-900;
}

@mixin links-mobile {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0;
  color: $avi-green;
}

// Responsiveness
@mixin respond-to-max($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)-1px) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin respond-to-min($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin respond-between($lower, $upper) {
  // Get the lower and upper breakpoint values, if they exist else default to given values
  $lower-breakpoint: map-get($breakpoints, $lower) or $lower;
  $upper-breakpoint: map-get($breakpoints, $upper) or $upper;

  @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
    @content;
  }
}

// DEV Note
//
// Usage :
// Make some element (practice picture for example) use the full screen width on Mobile
// while escaping the Page Container horizontal padding
@mixin full-width-content-on-mobile {
  @include respond-to-max('small') {
    margin-left: -(map-get($horizontal-page-paddings, 'small'));
    width: calc(100% + #{map-get($horizontal-page-paddings, 'small')} + #{map-get($horizontal-page-paddings, 'small')});
  }
}

/*****************************************/
/*************** PADDING *****************/
/*****************************************/

$padding-1: 8px;
$padding-2: 16px;
$padding-3: 24px;
$padding-4: 32px;

.px-1 {
  padding-left: $padding-1;
  padding-right: $padding-1;
}
.px-2 {
  padding-left: $padding-2;
  padding-right: $padding-2;
}
.px-3 {
  padding-left: $padding-3;
  padding-right: $padding-3;
}
.px-4 {
  padding-left: $padding-4;
  padding-right: $padding-4;
}

.py-1 {
  padding-top: $padding-1;
  padding-bottom: $padding-1;
}
.py-2 {
  padding-top: $padding-2;
  padding-bottom: $padding-2;
}
.py-3 {
  padding-top: $padding-3;
  padding-bottom: $padding-3;
}
.py-4 {
  padding-top: $padding-4;
  padding-bottom: $padding-4;
}
