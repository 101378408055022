@import 'scss/variables';
@import 'scss/mixins';

.header {
  .title:not(:has(+ .subtitle)) {
    margin-bottom: 32px;
  }

  .title:has(+ .subtitle) {
    margin-bottom: 16px;
  }

  .subtitle {
    margin-bottom: 32px;
  }

  @media screen and (max-width: map-get($breakpoints, 'small')) {
    .title:not(:has(+ .subtitle)) {
      margin-bottom: 24px;
    }

    .title:has(+ .subtitle) {
      margin-bottom: 12px;
    }

    .subtitle {
      margin-bottom: 24px;
    }
  }
}

