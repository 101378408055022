@import 'scss/variables';
@import 'scss/mixins';

.no-search-result {
  padding: 16px 24px;

  .no-match-title {
    padding-bottom: 32px;
    font-size: 18px;
  }
  .options-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 24px;
  }
}
