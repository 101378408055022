@import 'scss/variables';
@import 'scss/mixins';

.referral-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.free-text-input-container > input {
  font-family: 'ES Build Regular', sans-serif;
  font-size: 16px;
  color: $grey-800;
  width: 100%;
  padding: 16px 24px;
  border-radius: 4px;
  border: 1px solid $grey-600;
}
