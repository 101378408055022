@import 'scss/mixins';
@import 'scss/variables';

.booking-flow-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .booking-flow-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
}
