@import 'scss/mixins';
@import 'scss/variables';

@font-face {
  font-family: 'ES Build Medium';
  src: url('../assets/fonts/ESBuildFullNeutral-Medium.woff2');
  font-display: swap;
}
@font-face {
  font-family: 'ES Build Regular';
  src: url('../assets/fonts/ESBuildFullNeutral-Regular.woff2');
  font-display: swap;
}


[class*="font-"] {
  font-family: 'ES Build Regular', sans-serif;
  font-weight: 400;
  color: $grey-800;

  &[class*="sm"] {
    font-size: 14px;
    line-height: 18px;
  }

  &[class*="md"] {
    font-size: 16px;
    line-height: 21px;
  }

  &[class*="lg"] {
    font-size: 18px;
    line-height: 24px;
  }

  &[class*="bold"] {
        font-weight: 600;
    }

  &[class*="extra-bold"] {
      font-weight: 600;
    }
}
