@import 'scss/mixins';
@import 'scss/variables';

.page-container {
  display: grid;
  flex-grow: 1;
  max-width: 1200px;
  margin: 0 auto;
  gap: 24px;
  grid-template-columns: 2fr 1fr;
  padding: 80px map-get($horizontal-page-paddings, 'extra-large') 56px;
  width: 100%;

   @include respond-to-max('extra-large') {
    grid-template-columns: 1fr;
    padding: 80px map-get($horizontal-page-paddings, 'extra-large') 32px;
  }

  @include respond-to-max('large') {
    padding: 32px map-get($horizontal-page-paddings, 'large') 24px;
  }

  @include respond-to-max('medium') {
    padding: 24px map-get($horizontal-page-paddings, 'medium') 16px;
  }

  @include respond-to-max('extra-small') {
    padding: 16px map-get($horizontal-page-paddings, 'extra-small');
  }

  section:first-of-type {
    display: flex;
    flex-direction: column;

    @include respond-to-max('medium') {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
    }

    .page-content {
      flex-grow: 1;
    }
  }

  .side-content-container {
    @include respond-to-max('extra-large') {
      display: none;
    }
  }
}
