.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .loader-icon {
    filter: grayscale(1);
    opacity: 0.3;
  }
}
