@import 'scss/variables';
@import 'scss/mixins';

.address-form {
  .form-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 16px;
    margin: 16px 0;

    & > * {
      flex-grow: 1;
    }

    .small-input {
      max-width: 130px;
    }
  }

  .title-4 {
    margin-top: 24px;
  }
}
