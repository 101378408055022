@import 'scss/variables';
@import 'scss/mixins';

.pwa-button {
  @include button-label;
  position: relative;
  padding: 16px 24px;
  border-radius: 30px;
  border: solid 1px;
  cursor: pointer;
  user-select: none;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  &.primary, &.secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 24px;
    min-height: 54px;
    transition: background-color 0.15s, border-color 0.15s;
    white-space: nowrap;

    @include respond-to-max('small') {
      white-space: unset;
      font-weight: 500;
      font-size: 15px;
      min-height: 48px;
    }
  }

  &.primary {
    background-color: $avi-green;
    border-color: $avi-green;
    color: $white;

    @media (hover: hover) {
      &:hover {
        background-color: $darker-green;
        border-color: $darker-green;
      }
    }

    &:active {
      background-color: $dark-green;
      border-color: $dark-green;
    }

    &:disabled {
      cursor: not-allowed;
      color: $white;
      background-color: $grey-600;
      border: solid 2px $grey-600;
    }
  }

  &.secondary {
    color: $grey-800;
    background-color: $white;
    border-color: $grey-800;

    @media (hover: hover) {
      &:hover {
        color: $grey-900;
        background-color: rgb(247, 247, 247);
        border-color: $dark-green;
      }
    }

    &:active {
      color: $grey-900;
      background-color: rgb(232,232,232);
      border-color: $grey-900;
    }

    &:disabled {
      cursor: not-allowed;
      color: $grey-700;
      border-color: $grey-700;
    }
  }

  &.link {
    @include links;
    border: none;
    background: transparent;
  }

  &.with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border: none;
  }

  .icon {
    padding-right: 10px;
  }
}

.white-loader {
  filter: contrast(0) brightness(0) invert(1);
}
