@import 'scss/variables';
@import 'scss/mixins';

.pwa-textbox {
  position: relative;
  .input-container {
    position: relative;
  }

  .input-action {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    background: no-repeat center;
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: url('../../assets/images/icon_close.svg');
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='password'] {
    @include body;
    color: $grey-900;
    border: 1px solid $grey-700;
    padding: 24px 32px 16px 24px;
    width: 100%;
    border-radius: 8px;

    &:focus {
      border: 2px solid $avi-green;
      margin: -1px;
    }

    &.no-label {
      padding: 18px 14px;
    }

    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  input:-internal-autofill-selected {
    background-color: #fff !important;
  }

  input:placeholder-shown {
    padding: 20px;
  }

  input.text-center {
    text-align: center;
  }

  label {
    @include body;
    position: absolute;
    color: $grey-600;
    font-size: 13px;
    line-height: 12px;
    pointer-events: none;
    transition: 0.1s ease all;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    opacity: 1;
    top: 8px;
    padding: 0 16px;
  }

  &.invalid {
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'] {
      border: 1px solid $semantic-red;
    }

    label {
      color: $semantic-red;
    }
  }

  .error {
    display: block;
    padding-top: 8px;
    font-size: 14px;
    line-height: 14px;
    color: $semantic-red;
    white-space: pre-line;
  }
}

.pwa-textbox:has(input:placeholder-shown) label {
  opacity: 0;
}
