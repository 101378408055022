@import 'scss/variables';

.pwa-dropdown {
  user-select: none;
  border: 1px solid $grey-700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.04em;
  padding: 9px 40px 9px 16px;
  background: $white;
  position: relative;
  color: $grey-800;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  span {
    color: $grey-800;
  }

  &.noborder {
    border: none;
    box-shadow: $dropshadow;
  }

  .selected-option {
    white-space: nowrap;
    overflow: hidden;
  }

  .options ul {
    position: absolute;
    display: grid;
    height: 0;
    overflow: hidden;
    opacity: 0;
    grid-auto-rows: min-content;
    width: 100%;
    background: $white;
    box-shadow: 0px 4px 20px 2px rgba(79, 79, 79, 0.1);
    border-radius: 8px;
    left: 0;
    transition: all 0.25s ease-out;
    z-index: 2;

    li {
      padding: 9px 16px;
      color: $grey-800;

      &:hover {
        background-color: $light-peach;
      }

      &:active {
        background-color: $grey-peach;
      }

      .pwa-dropdown-note {
        font-size: 16px;
        line-height: 21px;
        color: $grey-700;
      }
    }

    .list-footer {
      padding-bottom: 22px;

      &:hover {
        background-color: $white;
      }
    }
  }

  &.open {
    border: solid 2px $avi-green;
    margin: -1px;

    & .options ul {
      height: auto;
      top: calc(100% + 2px);
      opacity: 1;
    }
  }

  .title {
    color: $grey-700;

    &.open {
      color: $avi-green;
    }
  }

  .caret {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    transition: all 0.25s ease-out;

    path {
      fill: $grey-800;
    }

    &.open {
      transform: translateY(-50%) rotate(180deg);

      path {
        fill: $avi-green;
      }
    }
  }

  .pwa-doctor-option {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto min-content;
    padding: 9px 0;
    gap: 8px;
    align-items: center;

    .photo {
      width: 32px;
      height: 32px;
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
    }

    .name {
      display: grid;
      grid-auto-flow: row;
      font-size: 18px;

      &.no-gap {
        gap: 0;
      }

      .position {
        margin-top: 2px;
        color: $grey-700;
        font-size: 14px;
        line-height: 16px;
      }

      .langs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .flag-and-name {
          padding: 1px 5px 1px 3px;
          margin-top: 6px;
          margin-right: 4px;
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: min-content;
          align-items: center;

          .lang-name-no-white-space {
            font-size: 0;
            padding-left: 4px;
            color: $grey-700;
          }

          .lang-name-no-white-space > span {
            font-size: 14px;
            line-height: 16px;
            word-break: keep-all;
            display: flex;
            text-transform: capitalize;
          }
        }
      }
    }

    .info {
      display: grid;
      align-content: center;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
