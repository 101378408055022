@import 'scss/variables';
@import 'scss/mixins';

.title {
  font-family: 'ES Build Regular', sans-serif;
  color: $grey-900;
  font-feature-settings: 'ss01' on;

  &.sm {
    font-family: 'ES Build Medium', sans-serif;
    font-size: 16px;
    line-height: 21px;
  }

  &.md {
    font-size: 20px;
    line-height: 26px;
  }

  &.lg {
    font-size: 25px;
    line-height: 33px;
  }

  &.xl {
    font-size: 32px;
    line-height: 42px;
  }

  @media screen and (max-width: map-get($breakpoints, 'small')) {
    &.sm {
      font-size: 14px;
      line-height: 18px;
    }

    &.md {
      font-size: 18px;
      line-height: 24px;
    }

    &.lg {
      font-size: 22px;
      line-height: 29px;
    }

    &.xl {
      font-size: 28px;
      line-height: 37px;
    }

  }
}
