@import 'scss/mixins';
@import 'scss/variables';

.insurance-selection {
  .loader-container {
    display: flex;
    z-index: 10;
    justify-content: center;
    padding-top: 80px;
    opacity: 1;
    filter: saturate(0);
    transition: opacity 0.15s;
  }

  .insurance-form-container {
    padding-bottom: 24px;
  }
}
