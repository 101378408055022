@import 'scss/variables';
@import 'scss/mixins';

.suggestion-container {
  display: flex;
  align-items: baseline;
  column-gap: 8px;

  .suggestion-type {
    font-size: 12px;
    color: $grey-600;
    font-weight: bold;
  }
}
