@import 'scss/mixins';
@import 'scss/variables';

.something-went-wrong-container {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  column-gap: 24px;
  row-gap: 24px;
  margin: 24px 0;
  background: $lightest-red;
  border-radius: 8px;
  width: fit-content;

  img {
    max-width: 200px;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
  }
}
