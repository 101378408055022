@import 'reset';
@import 'fonts';
@import 'variables';
@import 'mixins';

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  @include body;
  width: 100%;
  height: 100%;
}

.content {
  max-width: 1200px;
  justify-self: center;
}

body {
  @include body;
  background: $white;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

#root {
  min-height: 100%;
  display: grid;
}

.wrapper {
  background-color: $white;
  display: flex;
  flex-direction: column;

  @include respond-to-max('large') {
    max-width: map-get($breakpoints, 'large');
  }

  @include respond-to-max('medium') {
    max-width: map-get($breakpoints, 'medium');
  }
  @include respond-to-max('small') {
    max-width: map-get($breakpoints, 'small');
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  /************/
  /* Sections */
  /************/

  section.main {
    padding: 24px 60px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 2fr 1fr;
    gap: 24px;
    align-items: start;

    .column {
      display: grid;
      gap: 24px;
    }

    .mapboxgl-marker {
      width: 48px;
      height: 48px;
      background: url(../assets/images/map_marker.svg) center no-repeat;
      background-size: cover;
      cursor: default;

      div {
        opacity: 0;
        transition: all 0.25s ease-out;
        background: #fff;
        padding: 6px;
        transform: translate(-25%, -38px);
        color: $avi-green;
        border-radius: 4px;
        border: solid 1px $avi-green;
        position: absolute;
        white-space: nowrap;
      }

      &:hover div {
        opacity: 1;
      }
    }
  }

  section.about-us {
    display: grid;
    gap: 50px;
    grid-template-columns: auto 285px;
    margin-bottom: 60px;

    img {
      width: 100%;
    }
  }

  section.specialties {
    margin-bottom: 60px;

    & > div {
      width: 50%;
    }
  }

  section.our-doctors {
    .pwa-doctor-info {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: min-content 1fr;
      padding: 9px 0;
      gap: 16px;

      .photo {
        width: 64px;
        height: 64px;
        background-color: $white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
      }

      .name {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: auto 1fr;
        gap: 6px;

        .langs {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: min-content;
          gap: 6px;

          .lang {
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: $white;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }

      .info {
        display: grid;
        align-content: center;
        gap: 4px;
      }
    }
  }

  section.opening-times {
    .row {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr 1fr;
      align-items: center;
      padding: 8px 0;
    }
  }

  section.contacts {
    display: grid;
    grid-auto-flow: row;
    gap: 26px;

    .address,
    .email {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: min-content auto;
      gap: 10px;
    }

    .email {
      align-items: end;
    }
  }
}

::placeholder {
  color: $grey-700;
}

/**************/
/* Components */
/**************/

// Dropdowns
.react-dropdown-select {
  @include body;
  user-select: none;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid $grey-700 !important;
  border-radius: 8px !important;
  padding: 18px 9px !important;
  background: #fff;
  color: $grey-800 !important;
  -webkit-tap-highlight-color: transparent;

  &[aria-expanded='true'] {
    border: solid 2px $avi-green !important;
    margin: -1px;

    &.invalid {
      margin: -1px;
      border: 2px solid $semantic-red !important;
    }
  }

  ::placeholder {
    @include label;
    font-size: 16px;
  }

  &.invalid {
    border: 1px solid $semantic-red !important;
    color: $semantic-red !important;

    ::placeholder {
      color: $semantic-red;
    }
  }

  .react-dropdown-select-dropdown {
    box-shadow: 0 4px 20px 2px rgba(79, 79, 79, 0.1) !important;
    border-radius: 8px !important;
    top: 59px;
  }

  .react-dropdown-select-item {
    padding: 9px 16px !important;
    color: $grey-800 !important;

    &:hover {
      background-color: $grey-peach !important;
    }
  }

  .react-dropdown-select-item-selected {
    background: $light-peach !important;
  }
}

.react-dropdown-select-item {
  padding: 9px 16px !important;
  color: $grey-800 !important;

  &:hover {
    background-color: $grey-peach !important;
  }
}

// #region NOT USED STYLES BELOW.
// Model dialog
.pwa-modal-box-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.pwa-modal-box {
  .pwa-modal-boxes-wrapper {
    background: #ffffff;
    width: 690px;
    height: 460px;
    border-radius: 16px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-boxes-wrapper h3 {
    margin-bottom: 10px !important;
  }

  /* dialog-loading START */
  .modal-boxes-loading {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .appointment-icon {
    padding: 40px 0 15px 0;
  }

  .modal-boxes-loading img {
    margin-bottom: 16px;
    animation: rotate-loading 1s infinite linear;
  }
  @keyframes rotate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  /* dialog-loading END */

  /* dialog-appointment START */
  .modal-boxes-appointment-taken {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    min-width: 449px;
  }

  .modal-boxes-appointment-taken p {
    margin-bottom: 32px;
  }

  .modal-boxes-appointment-taken .pwa-btn {
    margin-bottom: 32px;
  }

  .modal-boxes-appointment-taken .btn-secondary {
    border: solid 3px #ffffff;
  }
  /* dialog-appointment END */

  /* dialog-email START */
  .close-modal {
    position: relative;
    top: 24px;
    left: calc(100% - 56px);
    width: 32px;
    height: 32px;
  }
  /* dialog-email END */
}

// #endregion

// Cards
.pwa-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.10);

  .title-2 {
    margin-bottom: 4px;
  }

  .subtitle2 {
    margin-bottom: 16px;
    font-size: 16px;
  }

  span.address {
    display: block;
  }

  .body {
    padding: 21px 24px;
  }

  .header-image {
    width: 100%;
    height: 200px;
    background-color: $medium-peach;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .appointment-info {
    padding: 16px 0;
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;

    span {
      display: grid;
      grid-template-columns: min-content auto;
      align-items: center;
      padding: 4px 0;

      &.empty {
        opacity: 0.5;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

// Date picker
.pwa-datepicker {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 8px;

  input[type='number'] {
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}

.error {
  color: $semantic-red;
}

::-moz-selection { /* Code for Firefox */
  background: $light-green;
}

::selection {
  background: $light-green;
}