@import 'scss/mixins';

.logo-position {
  display: flex;

  &.center-logo {
    justify-content: center;
  }
}

.logo {
  width: 94px;
  height: 24px;

  @include respond-to-max('medium') {
    width: 24px;
    height: 24px;
  }

  img {
    height: 100%;
  }
}