@import 'scss/variables';
@import 'scss/mixins';

.pwa-sidebar {
  user-select: none;
  background: $white;
  width: 235px;
  padding: 80px 12px 24px 0;
  position: sticky;
  left: 0;
  top: $header-height;
  height: calc(100vh - #{$header-height});
  flex-shrink: 0;

  @include respond-to-max('large') {
    display: none;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    color: $grey-800;
    padding-left: 24px;
    white-space: nowrap;
  }

  h3 {
    @include body;
    margin-top: 35px;
    padding-left: 24px;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }

  ul {
    display: block;

    &.active li {
      visibility: visible;
    }

    li {
      font-size: 14px;
      visibility: hidden;
      opacity: 0.5;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background-position: 24px center;
      background-repeat: no-repeat;
      pointer-events: none;
      background-size: 18px;
      background-position-x: 35px;

      .link {
        pointer-events: auto;
        cursor: pointer;
      }

      a {
        padding-left: 0;
        font-weight: inherit;
      }

      p {
        display: block;
        padding: 11px 16px 11px 64px;
      }

      &.active {
        background-color: $white;
        opacity: 1;
        background-image: url('../../assets/images/icon_pen.svg');
      }

      &.done {
        opacity: 1;
        background-image: url('../../assets/images/icon_check.svg');
      }
    }
  }
}
