@import 'scss/variables';
@import 'scss/mixins';

.no-address-search-result {
  padding: 16px 24px;

  .no-match-title {
    padding-bottom: 16px;
    font-size: 18px;
  }

  .no-match-description {
    padding-bottom: 8px;
    font-size: 16px;
  }
}
