// Responsiveness

$header-height: 72px;
$header-height-sm: 56px;
$banner-height: 110px;

$breakpoints: (
  'extra-small': 360px,
  'small': 576px,
  'medium': 768px,
  'large': 992px,
  'extra-large': 1440px,
) !default;

// Horizontal paddings used on the Page Container
$horizontal-page-paddings: (
  'extra-small': 12px,
  'small': 24px,
  'medium': 24px,
  'large': 32px,
  'extra-large': 32px,
) !default;

// Colours
$white: #ffffff;

$semantic-red: #c13c3c;
$illustration-red: #e75d58;
$lightest-red: #fff7f7;

$darker-green: #1c5254;
$dark-green: #0e484a;
$avi-green: #226a6d;
$bright-green: #198d92;
$light-green: #dae5e5;
$lightest-green: #f8fafa;

$dark-peach: #ffaa8f;
$peach: #ffceaa;
$medium-peach: #ffe0c2;
$grey-peach: #f8f1e8;
$light-peach: #fff8f0;
$lightest-peach: #fffefd;

$grey-900: #222222;
$grey-800: #444444;
$grey-700: #696969;
$grey-600: #7e7e7e;
$grey-500: #c0c0c0;
$grey-400: #dfdfdf;
$grey-300: #e7e7e7;
$grey-200: #eeeeee;
$grey-100: #f7f7f7;

$text-disabled: #999999;

// Specifics
$button-dropshadows: lighten(
    $color: $avi-green,
    $amount: 27.5%,
  )
  0px 0px 12px 0px;

$dropshadow: rgba(0, 0, 0, 0.1) 0 4px 12px;

$default-lr-padding: 24px;
$default-tb-padding: 11px;

@mixin new-font-style {
  @include body;
  font-size: 16px;
  color: $grey-800;
}

$cubicBezier: cubic-bezier(0.25, 0.46, 0.45, 0.94);
