@import 'scss/variables';
@import 'scss/mixins';

.modalContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.link {
  @include links;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.overlay {
  @extend .loadingOverlay;
}

@mixin baseWrapper {
  background: #fff;
  width: 560px;
  height: fit-content;
  max-height: calc(95vh);
  border-radius: 16px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;

  @include respond-to-max('medium') {
    width: calc(100% - 32px);
  }
}

.modal-dialog-message {
  white-space: pre-line;
}

.wrapper, .wrapperWithBanner {
  @include baseWrapper;

  @include respond-to-max('small') {
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
    z-index: 10;
    overflow: auto;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 -1px 4px rgba(0,0,0, 0.1), 0 -8px 10px rgba(0,0,0, 0.1);
  }
}

.loadingWrapper {
  @include baseWrapper;

  @include respond-to-max('small') {
    text-align: center;
    padding: 50px;
    height: auto;
    width: calc(100% - 32px);
  }
}

@mixin appointment {
  text-align: center;
  width: 100%;
  max-width: 450px;
  height: fit-content;
  padding: 24px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;


  .icon {
    padding: 16px 0 15px 0;
    width: 60px;
    margin: 0 auto;
  }

  p {
    @include body-big;
    max-width: 450px;

    strong,
    b {
      font-weight: 700;
    }
  }

  .actions {
    margin-top: 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    button:has(+span) {
      margin-bottom: 32px;
    }

    @include respond-to-min('small') {
      &.rowLayout {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        button, span {
          margin-bottom: 0;
          width: 50%;
        }

        span {
          text-align: left;
        }
      }
    }
  }


  @include respond-to-max('small') {
    transform: none;
    min-width: 0;
    padding: 0 16px;
    position: initial;
    text-align: center;
    margin: 36px auto;

    .actions {
      margin-top: 16px;
    }

    .icon {
      padding: 0 0 8px;
      width:48px;
    }

    p {
      padding: 8px;
      font-size: 18px;
      margin: auto;
    }
  }
}

.appointment {
  @include appointment;
}

.loading {
  @include respond-to-min('small') {
    @include appointment;
  }
}

.loading img {
  margin-bottom: 16px;
  width: 50px;
}

.appointment p {
  @include body-big;
  padding: 24px 0;
  max-width: 450px;
}

.close {
  position: relative;
  top: 24px;
  left: calc(100% - 32px);
  width: 32px;
  height: 32px;
  cursor: pointer;
  @include respond-to-max('small') {
    position: absolute;
  }
}
