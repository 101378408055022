@import 'scss/variables';
@import 'scss/mixins';

.suggestions-container {
  .suggestion-frequently-search-label {
      font-size: 16px;
      font-weight: 400;
      color: $grey-700;
      margin: 16px 24px;
  }

  .suggestion {
    padding: 16px 24px;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      background-color: $light-peach;
    }

    &:active {
      background-color: $grey-peach;
    }
  }
}

.no-search-result-container {
  padding: 24px 24px 8px;
  display: flex;
  align-items: flex-start;
  max-width: 790px;
}
